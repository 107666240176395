import React from "react";
import "./template.scss";
import { Main } from "./Components/Main";
import { Helmet } from "react-helmet";

function HeadComponent() {
  const theme = () => {
    return {
      title: "GlobalPass",
      favicon: "https://cdn.globalpass.ch/assets/favicon_globalpass.ico",
    };
  };

  return (
    <Helmet>
      <link rel="icon" href={theme().favicon} />
      <title>{theme().title}</title>
    </Helmet>
  );
}

function App() {
  return (
    <>
      <Main />
      <HeadComponent />
    </>
  );
}

export default App;

import axios from "axios";

export type OnboardingRequestResponse = {
    companyName: string;
    isCompleted: boolean;
    widgetVersion?: string;
    widgetToken?: string;
  }

export const getScreeningData = async (token: string) => {
    var result =  await axios.get<OnboardingRequestResponse>(process.env.REACT_APP_SCREENINGS_URL + `/api/v2/onboarding-requests/${token}`);
    return result
}
